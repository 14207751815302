.search-input {
  width:100%;
  border-radius:10;
}

.samrat{
  width:140px;
}

.focused {
  width:200px;
  margin-left:-260px;
  transition: all 0.3s ease-in-out;
}

.megamenu{
  width:0%;
  margin-left:-15,
}
.menu{
  width:0%;

}

.clicked {
  margin-left:0;
  width:80%;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .mobileview {
    display: flex;
  }
  .compview {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .mobileview {
    display: none;
  }
  .compview {
    display: flex;
  }
}

  
