@media only screen and (max-width: 767px) {
  .mobileview {
    display: flex;
  }
  .compview {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .mobileview {
    display: none;
  }
  .compview {
    display: flex;
  }
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-content {
  transition: transform 2s linear;
  display: flex;
  gap: 20px; /* Adjust the gap as needed */
}

@media (max-width: 768px) {
  .scroll-content {
    transition: transform 2s linear; /* Adjust timing for smaller screens */
  }
}

.flexslider-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.flexslider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  white-space: nowrap;
}

.flexslider-item {
  flex: 0 0 auto;
  width: 100%; /* Ensure each item takes the full width */ /* Adjust the spacing between items */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

.flexslider-item:last-child {
  margin-right: 0; /* Remove the margin from the last item */
}

.flexslider-item img {
  max-width: 100%; /* Limit the image width to the item width */
  height: auto;
}